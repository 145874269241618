import "@babel/polyfill"
import Vue from "vue"
import "mutationobserver-shim"
import "./Utils/fliter"
import App from "./App.vue"
import router from "./router"
import store from "./store.js"
import Raphael from "raphael/raphael"
import "./plugins"
import "./registerServiceWorker"
import i18n from "./i18n"
import "./directives"
import AlertServie from "./services/errors"
import ApiService from "./services/api"
import VuePlyr from "vue-plyr"
import "vue-plyr/dist/vue-plyr.css"
import VueCookies from "vue-cookies"
import VueGeolocation from "vue-browser-geolocation"
import axios from "axios"

function getIdFromUrl(query = "id") {
  // Create a new URLSearchParams object with the query string
  const params = new URLSearchParams(window.location.search)

  // Check if the 'id' parameter exists
  if (params.has(query)) {
    // Return the value of the 'id' parameter
    return params.get(query)
  } else {
    // Return null if the 'id' parameter is not found
    return null
  }
}

function loadApp() {
  if (!localStorage.getItem("lang")) {
    localStorage.setItem("lang", "ar")
    this.$i18n.locale = "ar"
  }
  Vue.use(VueGeolocation)
  ApiService.init()
  AlertServie.init()
  global.Raphael = Raphael

  Vue.config.productionTip = false

  Vue.use(VuePlyr, {
    plyr: {},
  })

  Vue.use(VueCookies)

  const vm = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app")

  window.vm = vm
}

;(async () => {
  localStorage.setItem("countryCode", "964")
  const id = getIdFromUrl("id")
  if (id) {
    await ApiService.refreshToken(id).finally(() => {
      loadApp()
    })
  } else {
    await axios
      .get("https://high-fit.net:3459/api/v1" + "/auth/ip/info")
      .then((response) => {
        localStorage.setItem("countryCode", response.data.data)
      })
      .finally(() => {
        loadApp()
      })
  }
})()
